import axios from 'axios';
import { AUTHENTICATION_LOGOUT } from '../store/actions/authentication';
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
import 'vue-loading-overlay/dist/vue-loading.css';
import config from '.';

export function initializeAxios(app) {
    let loaders = [];

    axios.defaults.baseURL = `${config.BACK_URL}/${config.API}/`;
    let token = localStorage.getItem('token');
    if (token !== null) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }

    axios.interceptors.request.use(function (config) {
        if (config.showPreloader) loaders.push(app.$loading.show({
            'background-color': '#000000',
            'color': '#FFFFFF'
        }));

        return config;
    }, function (error) {
        loaders = hideLoader(loaders);

        return Promise.reject(error);
    });

    axios.interceptors.response.use(function(response) {
        loaders = hideLoader(loaders);
        
        if (response.config.toastSuccessMessage) {
            createToast(response.config.toastSuccessMessage, {
                position: 'bottom-right',
                type: 'success',
                hideProgressBar: true,
                showIcon: true,
                transition: 'slide',
            });
        }

        return response;
    }, function(error) {
        loaders = hideLoader(loaders);
        let errorMessage = null;
        
        if (error.config.toastError || error.config.toastErrorMessage) {
            errorMessage = `Une erreur côté serveur s'est produite`;
            if (error.config.toastErrorMessage) {
                errorMessage = error.config.toastErrorMessage;
            } else if (error?.response?.data && error?.response?.data?.statusCode == null) {
                errorMessage = error.response.data;
            }
        }

        if (error.status === 401 || error?.response?.status === 401) {
            if (app.$store.getters.isAuthenticated) {
                app.$router.push({ path: '/connexion' });
                errorMessage = `Votre session a expirée, merci de vous authentifier`;
            } else if (app.$router.currentRoute.value.name === 'connexion') {
                errorMessage = error?.response?.data || `Identifiant ou mot de passe incorrect`;
            } else {
                errorMessage = null;
            }
            app.$store.dispatch(AUTHENTICATION_LOGOUT, { cancelRedirect: true });
        } else if (error.status === 403 || error?.response?.status === 403) {
            app.$router.push({ path: '/' });
            if (error?.response?.data) {
                errorMessage = error.response.data;
            } else {
                errorMessage = `Vous ne disposez pas des droits pour accéder à cette page`;
            }
        }

        if (errorMessage !== null) {
            if (errorMessage.trim().length === 0) errorMessage = `Une erreur côté serveur s'est produite`;

            createToast(errorMessage, {
                position: 'bottom-right',
                type: 'danger',
                hideProgressBar: true,
                showIcon: true,
                transition: 'slide'
            });
        }

        return Promise.reject(error);
    });
}

function hideLoader(loaders) {
    if (loaders.length > 0) {
        for (let loader of loaders) {
            loader.hide();
        }
        loaders = [];
    }
    return loaders;
}