<template>
    <Modal v-if="isVisible" class="dialog">
        <template v-slot:modalTitle>
            <div>{{ title }}</div>
        </template>
        <template v-slot:modalContent>
            <div v-html="message"></div>
            <BaseInput
                v-if="type === 'prompt' && promptMode === 'input'"
                v-model="promptModel"
                :label="label"
                :type="promptType"
            >
            </BaseInput>
            <BaseDatepicker
                v-if="type === 'prompt' && (promptMode === 'date' || promptMode === 'dateTime')"
                v-model="promptModel"
                :label="label"
                :displayTime="promptMode === 'dateTime'"
            />
        </template>
        <template v-slot:modalButtons>
            <BaseButton v-if="type === 'confirm' || type === 'prompt'" class="white-button" @click="cancelDialog" :buttonText="cancelButton">
                <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'times']" />
                </template>
            </BaseButton>
            <BaseButton class="orange-button" @click="confirmDialog" :buttonText="okButton">
                <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'check']" />
                </template>
            </BaseButton>
        </template>
    </Modal>
</template>

<script>
    import Modal from '../App/Modal'
    import BaseInput from '../Base/BaseInput'
    import BaseButton from '../Base/BaseButton'
    import BaseDatepicker from '../Base/BaseDatepicker'

    export default {
        name: 'Dialog',
        components: {
            Modal,
            BaseInput,
            BaseButton,
            BaseDatepicker
        },
        data: () => ({
            isVisible: false,
            type: 'alert', // 'alert', 'confirm' ou 'prompt'
            promptMode: null,
            promptType: 'text', // 'text' ou 'textarea'
            title: null,
            message: null,
            promptModel: null,
            okButton: 'Valider',
            cancelButton: 'Annuler',
            resolvePromise: undefined,
            rejectPromise: undefined,
            label: ''
        }),
        methods: {
            show(opts = {}) {
                this.title = opts.title;
                this.message = opts.message;
                if (opts.type) this.type = opts.type;
                if (opts.okButton) this.okButton = opts.okButton;
                if (opts.cancelButton) this.cancelButton = opts.cancelButton;
                if (opts.label) this.label = opts.label;
                if (opts.promptMode) this.promptMode = opts.promptMode;
                if (opts.promptType) this.promptType = opts.promptType;
                if (opts.value) this.promptModel = opts.value;
                this.isVisible = true;

                return new Promise((resolve, reject) => {
                    this.resolvePromise = resolve;
                    this.rejectPromise = reject;
                })
            },
            confirmDialog() {
                this.isVisible = false;
                if (this.type === 'prompt') {
                    this.resolvePromise(this.promptModel);
                    this.promptModel = null;
                } else {
                    this.resolvePromise(true);
                }
            },
            cancelDialog() {
                this.isVisible = false;
                if (this.type === 'prompt') {
                    this.rejectPromise();
                    this.promptModel = null;
                } else {
                    this.rejectPromise();
                }
            }
        }
    }
</script>