<template>
    <component :is="layout">
        <slot />
    </component>
</template>

<script>
    import AppLayoutDefault from './AppLayoutDefault'
    import AppLayoutNoPadding from './AppLayoutNoPadding'
    import AppLayoutNoPaddingNoBackground from './AppLayoutNoPaddingNoBackground'
    import AppNoLayout from './AppNoLayout'
    import { shallowRef } from 'vue'

    export default {
        name: 'AppLayout',
        components: {
            AppLayoutDefault,
            AppLayoutNoPadding,
            AppLayoutNoPaddingNoBackground,
            AppNoLayout
        },
        data() {
            return {
                layout: null
            }
        },
        watch: {
            $route(to) {
                if(to.meta.layout !== undefined) {
                    this.layout = to.meta.layout;
                } 
                else {
                    this.layout = shallowRef(AppLayoutDefault);
                }
            }
        }
    }
</script>
