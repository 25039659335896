<style scoped>
    .modal {
        position: fixed;
        z-index: 1001;
        width: 100vw;
        min-height: 100vh;
        background-color: rgba(0, 10, 20, 0.5);
        box-sizing: border-box;
        overflow-y: auto;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
	    justify-content: center !important;
	    align-items: center !important;
        overflow-x: hidden;
    }

    .modal-content {
        background-color: white;
        border-radius: 4px;
        box-shadow: 0px 10px 25px rgba(0, 10, 20, 0.3), 0px 2px 6px rgba(0, 10, 20, 0.1);
        position: relative;
        box-sizing: border-box;
        height: fit-content;
        width: fit-content;
        margin: 50px;
    }

    .modal-content > div {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .modal-fullscreen-content {
        height: 98%;
        width: 98%;
        margin: 0px !important;
        max-height: unset !important;
    }

    .modal-title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 30px 30px 20px 30px;
        border-bottom: 1px solid rgb(220, 225, 230);
    }

    .modal:not(.dialog) .modal-title > .modal-icon {
        background: linear-gradient(90deg, #1e73be, #00a1cf);
        position: relative;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .modal.dialog .modal-title {
        padding: 15px 20px 15px 20px;
    }

    .modal-title > div:last-child {
        font-size: 18px;
        font-weight: 600;
        text-transform: uppercase;
        color: #0D3B70;
        margin-top: 10px;
    }

    ::v-slotted(form > div) {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content:center;
    }

    ::v-slotted(form.form-column) {
        padding: 30px 20px 0 0px !important;
    }

    ::v-slotted(form.form-column > div) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin: 0px 15px 20px 15px;
    }

    ::v-slotted(form > div) {
        align-items: flex-start;
    }

    .modal-title {
        position: -webkit-sticky;
        position: sticky;
        top: 0px;
        background-color: white;
        z-index: 999;
    }

    .modal-buttons {
        margin-top: auto;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px 0 15px 0;
        position: -webkit-sticky;
        position: sticky;
        bottom: 0px;
        background-color: white;
        z-index: 999;
    }

    .modal-content {
        max-height: calc(100vh - 100px);
    }

    .modal-content {
        overflow: hidden;
    }

    .modal-container {
        overflow-y: auto;
        overflow-x: hidden;
        min-height: 300px;
        max-height: calc(100vh - 300px);
    }

    .tablet .modal-content {
        max-height: 676px;
    }

    .tablet .modal-container {
        max-height: 476px;
    }

    .modal-buttons > * {
        margin: 0 10px;
    }

    ::v-slotted(.modal-container form) {
        padding: 30px 15px 0 15px;
    }

    ::v-slotted(.modal-container form.form-column) {
        display: flex;
    }

    .modal.dialog .modal-container {
        padding: 30px 30px 20px 30px;
        min-height: unset;
    }

    .modal.auto-height .modal-container {
        padding: 30px 30px 20px 30px;
        min-height: unset;
    }

    .modal-content.mode-menu {
        display: flex;
    }

    .modal-container {
        z-index: 99;
    }

    .modal-fullscreen-content .modal-container {
        max-height: 100vh !important;
    }
</style>

<template>
    <div class="modal">
        <div class="modal-content" :class="(fullscreen === true ? 'modal-fullscreen-content' : '') + ' mode-' + mode">
            <div v-if="mode === 'modal'">
                <div class="modal-title" v-if="!hideTitle">
                    <div class="modal-icon" v-if="!hideIcon">
                        <slot name="modalIcon"></slot>
                    </div>
                    <div>
                        <slot name="modalTitle"></slot>
                    </div>
                </div>
                <div class="modal-container">
                    <slot name="modalContent"></slot>
                </div>
                <div class="modal-buttons" v-if="$slots.modalButtons">
                    <slot name="modalButtons"></slot>
                </div>
            </div>
            <slot name="modalStep" v-if="mode === 'step'" />
            <slot name="modalMenu" v-if="mode === 'menu'" />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Modal',
        props: {
            mode: {
                type: String,
                default: 'modal'
            },
            fullscreen: {
                type: Boolean,
                default: false,
            },
            hideTitle: {
                type: Boolean,
                default: false,
            },
            hideIcon:  {
                type: Boolean,
                default: false,
            }
        }
    }
</script>