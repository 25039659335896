<style src="../../assets/css/inputeo.css" scoped></style>

<style scoped>
    .inputeo.inputeo-small label {
        font-size: 9px;
        margin: 5px 8px 0px 8px;
        font-weight: 100;
    }

    .inputeo.inputeo-small input {
        padding: 5px 8px 8px 8px;
        font-size: 11px;
    }

    .inputeo.inputeo-medium label {
        font-size: 11px;
        margin: 5px 8px 0px 8px;
        font-weight: 100;
    }

    .inputeo .iconAction {
        padding-right: 40px;
    }

    .button-action {
        background-color: rgb(221, 223, 225);
        width: 18px;
        height: 18px;
        position: absolute;
        right: 10px;
        top: 5px;
        color: #F6F8FA;
        font-size: 13px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2px;
        border-radius: 2px;
        z-index: 50;
    }
    .button-action:hover {
        cursor: pointer;
    }
</style>

<template>
    <div class="inputeo" :class="{ 'inputeo-icon': $slots.iconBefore, 'inputeo-small': isSmall, 'inputeo-medium': isMedium }" :style="`min-height: ${minHeight}`">
        <div v-if="displayError && error" class="invalid-mark">
            <div class="invalid-mark-message-container">
                <div class="invalid-mark-message">{{ error }}</div>
            </div>
        </div>
        <div class="fa-icon" v-if="$slots.iconBefore">
            <slot name="iconBefore"></slot>
        </div>
        <label v-if="label">{{ label }}</label>
        <div class="button-action" v-if="$slots.iconAction" @click="onButtonActionClick">
            <slot name="iconAction"></slot>
        </div>
        <textarea
            v-if="type === 'textarea'"
            :id="id"
            v-bind="$attrs"
            :value="modelValue"
            :placeholder="placeholder"
            :tabIndex="tabIndex"
            @input="onInput"
            :disabled="disabled"
            :class="{'disabled': disabled, 'iconAction': $slots.iconAction}"
            ref="textarea"
            ></textarea>
        <input
            v-else
            :id="id"
            v-bind="$attrs"
            :value="modelValue"
            :type="type"
            :placeholder="placeholder"
            :tabIndex="tabIndex"
            @input="onInput"
            @keyup="onKeyUp"
            :disabled="disabled"
            :class="{'disabled': disabled, 'iconAction': $slots.iconAction}"
        />
    </div>
</template>

<script>
    import $ from 'jquery';
    import {formatPrice, formatNumber, formatDecimal} from '../../utils/formatter'

    export default {
        name: 'BaseInput',
        props: {
            label: {
                type: String,
                default: ''
            },
            placeholder: {
                type: String,
                default: ''
            },
            modelValue: {
                type: [Number, String],
                default: ''
            },
            type: {
                type: String,
                default: 'text'
            },
            name: {
                type: String,
                default: ''
            },
            displayError: {
                type: Boolean,
                default: false
            },
            validator: {
                type: String,
                default: ''
            },
            error: {
                type: String,
                default: ''
            },
            minLength: {
                type: String,
                default: '-1'
            },
            maxLength: {
                type: String,
                default: '-1'
            },
            tabIndex: {
                type: String,
                default: '1'
            },
            required: {
                type: Boolean,
                default: true
            },
            onKeyUp: {
                type: Function,
                default: () => {}
            },
            disabled: {
                type: Boolean,
                default: false
            },
            isSmall: {
                type: Boolean,
                default: false
            },
            isMedium: {
                type: Boolean,
                default: false
            },
            autoWidth: {
                type: Boolean,
                default: false
            },
            autoHeight: {
                type: Boolean,
                default: false
            },
            allowNegative: {
                type: Boolean,
                default: null
            },
            defaultValue: {
                type: String,
                default: null
            },
            minHeight: {
                type: String,
                default: 'auto'
            }
        },
        watch: {
            modelValue: {
                handler(modelValue) {
                    this.checkMask(null, modelValue, this.isFromUser ? 'user' : 'auto');
                    this.isFromUser = false;
                }
            },
            required: {
                handler() {
                    this.checkMask(null, this.modelValue, this.isFromUser ? 'user' : 'auto');
                }
            },
            defaultValue: {
                handler() {
                    if (this.defaultValue != null && this.modelValue == null) {
                        this.$emit('update:modelValue', this.defaultValue);
                    }
                }
            }
        },
        data() {
            return {
                isFromUser: false,
                id: null,
            }
        },
        mounted() {
            this.id = Math.floor(Math.random() * Date.now());
            this.checkMask(null, this.modelValue, 'init');

            if (this.defaultValue != null) {
                this.$emit('update:modelValue', this.defaultValue);
            }

            this.emitter.on('resizeHeight', () => {
                let that = this;

                this.$nextTick(() => {
                    that.resizeHeight();
                });
            });
        },
        methods: {
            checkMask(event, value, changeType) {
                let shiftCursor = 0;

                // Le cas où on accepte pas de valeur négatives
                if (this.allowNegative != null && !this.allowNegative && value?.toString()?.startsWith('-'))
                  value = parseInt(value?.substring(1))

                // On formate les données en fonction du type de validation
                switch (this.validator) {
                    case 'price':
                        if (value != null) {
                            value = formatPrice(value, event);
                        }
                    break;
                    case 'number':
                        if (value != null) {
                            value = formatNumber(value);
                        }
                    break;
                    case 'decimal':
                        if (value != null) {
                            value = formatDecimal(value);
                        }
                    break;
                    case 'decimal_2':
                        if (value != null) {
                            value = formatDecimal(value, 2);
                        }
                    break;
                    case 'phone':
                        if (value != null) {
                            let valueTrim = value.replace(/\s+/g, '');

                            if (valueTrim.length > 2 && valueTrim.length % 2 === 1 && value.substring(value.length - 2, value.length - 1) !== ' ') {
                                value = value.substr(0, value.length - 1) + ' ' + value.substring(value.length - 1);
                                shiftCursor = 1;
                            }
                        }
                    break;
                }

                if (this.maxLength != null && parseInt(this.maxLength) >= 0) {
                    if (value?.length > this.maxLength) {
                        value = value.substring(0, value.length - 1)
                    }
                }

                if (event != null) {
                    // On récupère la position du curseur avant le changement de valeur
                    let selection = event.target.selectionStart;

                    event.target.value = value;
                    if (this.type !== 'number') {
                        // On gère la position du curseur
                        event.target.setSelectionRange(selection + shiftCursor, selection + shiftCursor);
                    }

                    // On émet l'événement afin de mettre à jour le modèle
                    this.$emit('update:modelValue', value);

                    this.checkField(value, changeType);
                } else {
                    // On émet l'événement afin de mettre à jour le modèle
                    this.$emit('update:modelValue', value);

                    this.checkField(value, changeType);
                }

                if (this.autoWidth) {
                    let that = this;
                    this.$nextTick(() => {
                        let currentFontSize = window.getComputedStyle(that.$el.children[0], null).getPropertyValue('font-size');
                        let currentFontFamily = window.getComputedStyle(that.$el.children[0], null).getPropertyValue('font-family');
                        let currentFontWeight = window.getComputedStyle(that.$el.children[0], null).getPropertyValue('font-weight');
                        let currentTextTransform = window.getComputedStyle(that.$el.children[0], null).getPropertyValue('text-transform');

                        let tmp = document.createElement("span");
                        tmp.style = `border: 0; font: ${currentFontSize} ${currentFontFamily}; font-weight: ${currentFontWeight}; text-transform: ${currentTextTransform}; padding: 0px 2px; visibility: hidden; position: absolute; white-space: pre;`;
                        tmp.innerHTML = value?.replace(/&/g,'&amp;').replace(/</g,'&lt;').replace(/>/g,'&gt;') ?? '';
                        document.body.appendChild(tmp);
                        var width = tmp.getBoundingClientRect().width;

                        $(`#${that.id}`).css('width', value == null || width < 20 ? '20px' : `${width}px`);
                    });
                }

                if (this.autoHeight) {
                    let that = this;

                    this.$nextTick(() => {
                        that.resizeHeight();
                    });
                }
            },
            checkField(value, changeType) {
                let error = null;

                // On vérifie les données saisies en fonction du type de validation
                if (this.required === true || (value != null &&  String(value).trim().length > 0)) {
                    if (this.required && (value == null || String(value).trim().length === 0)) {
                         error = `Ce champ est requis`;
                    } else {
                        switch (this.validator) {
                            case 'password':
                                if (!value.trim().match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.])[A-Za-z\d@$!%*?&]{8,}$/)) {
                                    error = `Le mot de passe doit contenir 8 caractères dont au moins une majuscule, une minuscule et un caractère spécial`;
                                }
                            break;
                            case 'email':
                                if (!value.trim().match(/^(([a-zA-Z0-9_.-])+@([a-zA-Z0-9_.-])+\.([a-zA-Z])+([a-zA-Z])+){1,}$/g)) {
                                    error = `L'email doit être valide`;
                                }
                            break;
                            case 'price':
                                if (value.trim() === '€') {
                                    error = `Le prix doit être valide`;
                                }
                            break;
                            case 'number':
                                if (String(value).trim().length === 0) {
                                    error = `Ce champ est requis`;
                                }
                            break;
                            case 'decimal':
                                if (!value.trim().match(/^\d+(\.\d{1,2})?$/g)) {
                                    error = `Ce champ est requis`;
                                }
                            break;
                            case 'decimal_2':
                                if (!value.trim().match(/^\d+\.\d{0,2}$/g)) {
                                    error = `Ce champ est requis`;
                                }
                            break;
                            case 'phone':
                                if (!value.trim().replace(/\s+/g, '').match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im)) {
                                    error = `Le téléphone doit être valide`;
                                }
                            break;
                            case 'website':
                                if (!value.trim().match(/^(http|https):\/\/[^ "]+$/g)) {
                                    error = `Le site web doit être valide`;
                                }
                            break;
                            /*case 'customerAccountNumber':
                                    if (!value.trim().match(/^0[0-9a-zA-Z]{6}0$/g) || value.length !== 8) {
                                    error = `Le numéro de compte doit être valide (8 chiffres commençant et finissant par 0)`;
                                }
                            break;*/
                            case 'supplierAccountNumber':
                                if (!value.trim().match(/^9[0-9]{7}/g) || value.length !== 8) {
                                    error = `Le numéro de compte doit être valide (8 chiffres commençant par 9)`;
                                }
                            break;
                        }
                    }
                }

                // Si on a spécifié une longueur minimale et/ou maximale
                if (value != null) {
                    if (parseInt(this.minLength) !== -1 && value.trim().length < parseInt(this.minLength)) {
                        error = `Merci de saisir au moins ${this.minLength} caractère${parseInt(this.minLength) > 1 ? 's' : ''}`;
                    }  else if (parseInt(this.maxLength) !== -1 && value.trim().length > this.maxLength) {
                        error = `Merci de saisir au maximum ${this.maxLength} caractère${parseInt(this.maxLength) > 1 ? 's' : ''}`;
                    }
                }

                // On émet l'événement pour spécifier qu'il y a un changement
                this.$emit('onChange', {
                    name: this.name,
                    value: value,
                    error: {
                        type: 'validator',
                        message: error
                    },
                    changeType: changeType
                });
            },
            onInput(event) {
                this.isFromUser = true;

                if (this.type === 'number' && (event.data === '.' || event.data === ',' || (this.allowNegative && event.data === '-'))) {
                    return;
                }

                this.checkMask(event, event.target.value, 'user');
            },
           onButtonActionClick() {
                this.$emit('onButtonActionClick');
           },
           resizeHeight() {
                if (this.type === "textarea") {
                    const element = this.$refs.textarea;
                    if (element) {
                        element.style.height = "16px";
                        if (element.scrollHeight > 16) element.style.height = element.scrollHeight + "px";
                    }
                }
           }
        }
    }
</script>