export default Object.freeze({
    PENDING_ADMIN_VALIDATION: "pendingAdminValidation",
    PENDING_EXPERT_ASSESSMENT_PLANNING: "pendingExpertAssessmentPlanning",
    PENDING_EXPERT_ASSESSMENT: "pendingExpertAssessment",
    ONGOING_EXPERT_ASSESSMENT: "ongoingExpertAssessment",
    PENDING_EXPERT_ASSESSMENT_VALIDATION: "pendingExpertAssessmentValidation",
    EXPERT_ASSESSMENT_VALIDATED_PENDING_NOMENCLATURE: "expertAssessmentValidatedPendingNomenclature",
    NOMENCLATURE_VALIDATED_PENDING_EXPERT_ASSESSMENT: "nomenclatureValidatedPendingExpertAssessment",
    PENDING_QUOTATION: "pendingQuotation",
    WAITING_FOR_SUBCONTRACTED_PARTS: "waitingForSubcontractedParts",
    NOT_REPAIRABLE: "notRepairable",
    TO_WRECK: "toWreck",
    PENDING_REPAIRS: "pendingRepairs",
    ONGOING_REPAIRS: "ongoingRepairs",
    PENDING_TESTS_PLANNING: "pendingTestsPlanning",
    PENDING_TESTS: "pendingTests",
    ONGOING_TESTS: "ongoingTests",
    TO_SHIP: "toShip",
    TO_BILL: "toBill",
    BILLED: "billed",
    PENDING_CUSTOMER_DECISION: "pendingCustomerDecision",
    EXTERNAL_REPAIRS: "externalRepairs",
    CANCELED: "canceled",
    TEST_BENCH: "testBench",
    RETURN_WITHOUT_REPAIR: "returnWithoutRepair",
    SPINDLE_HOTEL: "spindleHotel",
});