import { createStore } from 'vuex'
import authentication from './modules/authentication';
import affairStatus from './modules/affairStatus';
import dropboxToken from "@/store/modules/dropboxToken";

export default createStore({
    modules: {
        authentication,
        affairStatus,
        dropboxToken
    }
});
