import {DROPBOX_REFRESH_ACCESS_TOKEN} from "@/store/actions/dropboxToken";
import config from "@/config";


const actions = {
    [DROPBOX_REFRESH_ACCESS_TOKEN]: (context, refreshToken) => {
        return new Promise((resolve, reject) => {
            /**
             * NOTE: Cette api refuse le header 'Authorizations' ce qui empêche l'utilisation d'axios
             */
            fetch('https://api.dropbox.com/oauth2/token', {
                method: 'POST',
                body: new URLSearchParams({
                    refresh_token: refreshToken,
                    grant_type: "refresh_token",
                    client_id: config.DROPBOX_APP_KEY,
                    client_secret: config.DROPBOX_APP_SECRET
                })
            }).then((response) => {
                response.json().then((res) => {
                    if (res.access_token) {
                        localStorage.setItem('dropboxAccessToken', res.access_token);
                        resolve();
                    } else {
                        console.error("Échec de la récupération du token d'accès dropbox")
                        reject();
                    }
                })
                resolve();
            }).catch((error) => {
                console.error("Échec de la récupération du token d'accès dropbox")
                reject();
            })
        })
    }
}

export default {
    // state,
    // getters,
    actions,
    // mutations
};