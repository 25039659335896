<style src="../../assets/css/inputeo.css" scoped></style>

<style>
    .flatpickr-calendar.open, .flatpickr-calendar.inline {
        max-width: 240px;
    }

    .flatpickr-calendar {
        font-size: 11px;
    }

    .flatpickr-rContainer {
        width: 240px;
    }

    .flatpickr-days {
        width: 100%;
    }

    .flatpickr-day {
        border-radius: unset;
    }

    .dayContainer {
        min-width: 240px;
    }

    .flatpickr-months {
        font-size: 10px;
        margin-top: 3px;
    }

    .flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
        background: linear-gradient(90deg, #ff6b00, #ff9100);
        border-color: transparent;
    }

    .flatpickr-day.inRange, .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.today.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.nextMonthDay.today.inRange, .flatpickr-day:hover, .flatpickr-day.prevMonthDay:hover, .flatpickr-day.nextMonthDay:hover, .flatpickr-day:focus, .flatpickr-day.prevMonthDay:focus, .flatpickr-day.nextMonthDay:focus {
        background: #F6F8FA;
        border-color: #F6F8FA;
    }

    .flatpickr-months .flatpickr-prev-month:hover svg, .flatpickr-months .flatpickr-next-month:hover svg {
        fill: #181E23;
    }

    span.flatpickr-weekday {
        color: #323C46;
    }

    .flatpickr-day {
        color: #646E78;
    }
</style>

<template>
    <div class="inputeo" :class="{ 'inputeo-icon': $slots.iconBefore }">
        <div v-if="displayError && error" class="invalid-mark">
            <div class="invalid-mark-message-container">
                <div class="invalid-mark-message">{{ error }}</div>
            </div>
        </div>
        <div class="fa-icon" v-if="$slots.iconBefore">
            <slot name="iconBefore"></slot>
        </div>
        <label v-if="label">{{ label }}<span v-if="required">&nbsp;*</span></label>
        <flat-pickr 
            :modelValue="modelValue"
            :config="config"
            @change="onChange"
            :disabled="disabled"
            :class="disabled ? 'disabled' : ''"
            :autoClose="autoClose"
        >
        </flat-pickr>
    </div>
</template>

<script>
    import flatPickr from 'vue-flatpickr-component';
    import { French } from "flatpickr/dist/l10n/fr.js";
    import 'flatpickr/dist/flatpickr.css';

    export default {
        name: 'BaseDatepicker',
        components: { 
            flatPickr
        },
        props: {
            label: {
                type: String,
                default: ''
            },
            modelValue: {
                type: [String, Date],
                default: null
            },
            name: {
                type: String,
                default: ''
            },
            displayError: {
                type: Boolean,
                default: false
            },
            error: {
                type: String,
                default: ''
            },
            required: {
                type: Boolean,
                default: true
            },
            displayTime: {
                type: Boolean,
                default: false
            },
            onlyTime: {
                type: Boolean,
                default: false
            },
            onlyMonth: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            },
            autoClose: {
                type: Boolean,
                default: true
            }
        },
        watch: {
            modelValue: { 
                handler(modelValue) {
                    if (this.onlyTime == true && modelValue === "00:00") this.$emit('update:modelValue', null);
                    this.checkField(modelValue, this.isFromUser ? 'user' : 'auto');
                    this.isFromUser = false;
                },
            },
            required: {
                handler() {
                    this.checkField(this.modelValue, this.isFromUser ? 'user' : 'auto');
                }
            }
        },
        data() {
            return {
                config: {
                    enableTime: this.enableTime || this.displayTime || this.onlyTime,
                    noCalendar: this.onlyTime,
                    time_24hr: true,
                    dateFormat: this.onlyTime ? 'H:i' : this.displayTime ? 'd/m/Y H:i': this.onlyMonth ? 'm/Y' : 'd/m/Y',
                    locale: French,
                    disableMobile: true,
                    isFromUser: false,
                }
            }
        },
        mounted() {
            this.checkField(this.modelValue, 'init');

            if (this.autoClose) {
                this.emitter.on('scroll', (event) => {
                    // Si le datepicker est ouvert et qu'on scroll
                    if (event.target.className !== 'flatpickr-monthDropdown-months' && document.getElementsByClassName('flatpickr-calendar open').length > 0) {
                        const datepickerActives = document.querySelectorAll('.flatpickr-input.active[autoclose="true"]');
                        if (datepickerActives.length > 0) {
                            const datepicker = datepickerActives[0]._flatpickr;
                            datepicker.close();
                        }
                    }
                });
            }
        },
        methods: {
            checkField(data, changeType) {
                let error = null;

                // On vérifie s'il n'y a pas d'erreur
                if (this.required === true && (data == null || (typeof data === 'string' && data.trim().length === 0))) {
                    error = `Ce champ est requis`;
                }

                // On émet l'événement pour spécifier qu'il y a un changement
                this.$emit('onChange', {
                    name: this.name,
                    value: data,
                    error: {
                        type: 'validator',
                        message: error
                    },
                    changeType: changeType
                });
            },
            onChange(event) {
                this.isFromUser = true;
                this.$emit('update:modelValue', event.target.value);
            }
        }
    }
</script>