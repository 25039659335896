import { createApp } from 'vue'

import 'ag-grid-enterprise';
import App from './App.vue'
import router from './router'
import store from './store'
import mitt from 'mitt'
import AppLayout from '@/layouts/AppLayout'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { initializeAxios } from './config/axios'
import VueLoading from 'vue-loading-overlay'

import {
    faSignOutAlt,
    faCaretDown,
    faPlus,
    faUser,
    faKey,
    faSignInAlt,
    faUnlock,
    faLock,
    faPen,
    faTimes,
    faCheck,
    faSearch,
    faTrash,
    faTrashRestore,
    faCopy,
    faEdit,
    faFileUpload,
    faFilePdf,
    faFileExcel,
    faArrowLeft,
    faArrowRight,
    faClock,
    faUserTie,
    faPrint,
    faFileSignature,
    faFileAlt,
    faFileInvoice,
    faPaperPlane,
    faCamera,
    faCheckCircle,
    faChevronRight,
    faAddressBook,
    faHistory,
    faInfoCircle,
    faChevronLeft,
    faTools,
    faTimesCircle,
    faDumpster,
    faEnvelope,
    faTruck,
    faFileExport,
    faEye,
    faSave,
    faMapMarkerAlt,
    faPhone,
    faRedo,
    faSync,
    faArchive,
    faUndo,
    faMinusSquare,
    faWrench,
    faEuroSign,
    faLink,
    faTruckLoading,
    faBan,
    faHotel,
    faFile,
    faFilePowerpoint,
    faBoxOpen,
    faArrowUp,
    faArrowDown,
    faClone,
    faPhoneAlt, faTabletAlt, faReceipt, faFileDownload
} from '@fortawesome/free-solid-svg-icons'
import {
    faFolderDownload,
    faFileSearch,
    faFileCheck,
    faCommentCheck,
    faArrowToBottom,
    faUserHardHat,
    faChartLine,
    faChartBar,
    faTachometerAltFastest,
    faMoneyCheckEditAlt,
    faChartLineDown,
    faLayerGroup
} from '@fortawesome/pro-solid-svg-icons'
import { faCircle } from '@fortawesome/pro-regular-svg-icons';
import { faPlus as falPlus } from '@fortawesome/pro-light-svg-icons';
import {faChromecast, faDropbox} from '@fortawesome/free-brands-svg-icons';

library.add(faSignOutAlt, faCaretDown, faPlus, faLink, faUser, faKey, faSignInAlt, faUnlock, faLock, faPen, faTimes, faCheck, faSearch, faTrash, faTrashRestore, faCopy, faFileSearch, faFolderDownload, faEdit, faFileUpload, faFilePdf, faFileExcel, faArrowLeft, faArrowRight, faClock, faUserTie, faPrint, faFileSignature, faFileCheck, faFileAlt, faCommentCheck, faFileInvoice, faPaperPlane, faCamera, faCheckCircle, faChevronLeft, faChevronRight, faAddressBook, faHistory, faArrowToBottom, faInfoCircle, faCircle, faUserHardHat, faTools, faTimesCircle, faDumpster, faChromecast, faChartLine, faChartBar, faEnvelope, faCheckCircle, falPlus, faTruck, faFileExport, faEye, faSave, faMapMarkerAlt, faPhone, faRedo, faSync, faArchive, faUndo, faMinusSquare, faWrench, faTachometerAltFastest, faEuroSign, faMoneyCheckEditAlt, faTruckLoading, faBan, faChartLine, faHotel, faChartLineDown, faFile, faFilePowerpoint, faLock, faUnlock, faBoxOpen, faArrowUp, faArrowDown, faClone, faPhoneAlt, faTabletAlt, faDropbox, faReceipt, faFileDownload, faLayerGroup)

const app = createApp(App);

const rootComponent = app.use(store)
    .use(router)
    .use(VueLoading)
    .component('AppLayout', AppLayout)
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app')

initializeAxios(rootComponent);

app.config.globalProperties.exportCSV = (csvContent, fileName, addTimestamp = true) => {
    let encodedUri = encodeURI(csvContent);
    let link = document.createElement("a");
    link.setAttribute("href", "data:text/csv;charset=utf-8,\uFEFF" + encodedUri);
    if (addTimestamp) fileName += `_${new Date().toLocaleString().replace(/\//g, '_').replace(/:/g, '_').replace(/,/g, '').replace(/\s/g, '__')}`
    link.setAttribute("download", fileName + ".csv");
    document.body.appendChild(link);
    link.click();
}

const emitter = mitt();
app.config.globalProperties.numberItems = 20;
app.config.globalProperties.emitter = emitter;

window.addEventListener('scroll', (event) => {
    emitter.emit('scroll', event);
}, true);