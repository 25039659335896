<style scoped>
    #topbar {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 65px;
        background-color: #0d3b70;
        display: flex;
        align-items: center;
        z-index: 21;
        box-shadow: 0 4px 8px rgb(0 20 40 / 15%);
    }

    .shortcut-btn {
        width: 40px;
        height: 40px;
        color: white;
        margin-left: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.1);
        transition: 0.15s ease;
        border-radius: 4px;
    }

    .shortcut-btn:first-child {
        margin-left: 30px;
    }

    .shortcut-btn:hover {
        background-color: rgba(255, 255, 255, 0.2);
        cursor: pointer;
    }

    .shortcut-icon {
        width: 18px;
        height: 18px;
        transform: scaleX(-1);
    }

    #logo {
        height: 48px;
        width: auto;
        margin-left: 25px;
    }

    .mobile #logo {
        height: 26px;
        margin-left: 15px;
    }

    #menu {
        display: flex;
        height: 100%;
        margin-left: 30px;
    }

    #menu > * {
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        text-transform: uppercase;
        text-decoration: none;
        padding: 6px 6px 0 6px;
        margin: 0 8px;
        font-weight: 500;
        font-size: 15px;
        transition: 0.15s ease;
        position: relative;
        box-sizing: border-box;
    }

    #menu > a:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 60%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.1);
        transition: 0.15s ease;
        opacity: 0;
    }

    #menu > a:hover:before {
        width: 100%;
        opacity: 1;
    }

    #menu > a:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 0;
        background-color: rgba(255, 255, 255, 0);
        left: 0;
        bottom: 0;
    }

    #menu > a.selected:after {
        height: 5px;
        background-color: rgba(255, 255, 255, 0.9);
    }

    .submenu-container .fa-icon {
        width: 16px;
        height: 16px;
        color: rgba(255, 255, 255, 0.5);
        margin-left: 8px;
    }

    .submenu {
        position: absolute;
        top: 0;
        left: 50%;
        background-color: white;
        width: fit-content;
        box-sizing: border-box;
        border-radius: 4px;
        box-shadow: 0 4px 10px -3px rgba(0, 20, 40, 0.35);
        font-size: 12px;
        font-weight: 600;
        display: none;
        overflow: hidden;
    }

    .submenu a {
        white-space: nowrap;
        padding: 14px 20px;
        display: block;
        text-decoration: none;
        color: rgb(50, 60, 70);
        position: relative;
        transition: 0.15s ease-out;
    }

    .submenu a:not(:last-child) {
        border-bottom: 1px solid rgb(220, 225, 230);
    }

    .submenu a:hover {
        border-color: white;
    }

    .submenu a.selected:before {
        content: '';
        position: absolute;
        left: 0;
        top: -1px;
        height: calc(100% + 1px);
        width: 6px;
        background-color: #1e73be;
    }

    .submenu a div {
        top: -1px;
        left: 0;
        position: absolute;
        white-space: nowrap;
        color: white;
        background: linear-gradient(90deg, #1e73be, #00a1cf);
        box-sizing: border-box;
        height: calc(100% + 1px);
        width: 50%;
        transition: 0.15s ease-out;
        overflow: hidden;
        opacity: 0;
    }

    .submenu a div div {
        padding: 16px 20px;
    }

    .submenu a:hover div {
        width: 100%;
        opacity: 1;
    }

    .submenu div#username {
        color: rgb(50, 60, 70);
        padding: 14px 15px;
        font-size: 10px;
        text-align: center;
        border-bottom: 1px solid rgb(220, 225, 230);
        font-style: italic;
    }

    @media screen and (max-width: 1500px) {
        #menu > * {
            font-size: 12px;
            padding: 6px 5px 0 5px;
        }
        .submenu {
            font-size: 11px;
        }
        .submenu a {
            padding: 14px 15px;
        }
    }

    .mobile #menu {
        margin-left: 25px;
    }

    .mobile #menu > * {
        font-size: 10px;
        padding: 6px 5px 0 5px;
        margin-left: 25px;
    }

    .mobile .submenu {
        font-size: 10px;
    }

    .mobile .submenu a {
        padding: 14px 15px;
    }
</style>

<template>
    <div id="topbar">
        <div class="shortcut-btn" @click="refresh" aria-label="Bouton de rafraîchissement" v-if="$store.getters.isFromAppTablet && !$store.getters.isMobile">
            <font-awesome-icon class="shortcut-icon" :icon="['fas', 'sync']" />
        </div>
        <div class="shortcut-btn" @click="cast" aria-label="Bouton de cast" v-if="$store.getters.isFromAppTablet && !$store.getters.isMobile">
            <font-awesome-icon class="shortcut-icon" :icon="['fab', 'chromecast']" />
        </div>
        <router-link to="/">
            <picture>
                <source srcset="../../assets/images/white-logo.webp" type="image/webp">
                <source srcset="../../assets/images/white-logo.png" type="image/png">
                <img id="logo" src="../../assets/images/white-logo.webp" alt="Logo de Electrobroche Concept">
            </picture>
        </router-link>
        <div id="menu">
            <div class="submenu-container">
                PROFIL
                <font-awesome-icon class="fa-icon" :icon="['fas', 'caret-down']" />
                <div class="submenu">
                    <div id="username">
                        {{ $store.state.authentication.user.firstname }} {{ $store.state.authentication.user.lastname }}
                    </div>
                    <router-link to="/emails" :class="$route.name === 'emails' ? 'selected' : ''" v-if="$store.getters.isAdmin">
                        Emails
                        <div><div>Emails</div></div>
                    </router-link>
                    <router-link to="#" @click="logOut">
                        Déconnexion
                        <div><div>Déconnexion</div></div>
                    </router-link>
                </div>
            </div>
            <router-link :to="$store.getters.isTechnician || ($store.getters.isTechnicianManager && $store.getters.isFromAppTablet) ? '/affaires-tab' : '/affaires'" :class="$route.name === 'affaires-tab' || $route.name === 'affaires' ? 'selected' : ''">Affaires</router-link>
            <div class="submenu-container" v-if="$store.getters.hasRoleInList([accountRoleEnum.ADMIN, accountRoleEnum.WORKSHOP_MANAGER])">
                ACHATS
                <font-awesome-icon class="fa-icon" :icon="['fas', 'caret-down']" />
                <div class="submenu">
                    <router-link to="/fournisseurs" :class="$route.name === 'suppliers' ? 'selected' : ''">
                        Fournisseurs
                        <div><div>Fournisseurs</div></div>
                    </router-link>
                    <router-link to="/demandes-prix" :class="$route.name === 'price-requests' ? 'selected' : ''">
                        Demandes de prix
                        <div><div>Demandes de prix</div></div>
                    </router-link>
                    <router-link to="/commandes" :class="$route.name === 'orders' ? 'selected' : ''">
                        Commandes fournisseurs
                        <div><div>Commandes fournisseurs</div></div>
                    </router-link>
                    <router-link to="/receptions" :class="$route.name === 'receptions' ? 'selected' : ''">
                        Réceptions fournisseurs
                        <div><div>Réceptions fournisseurs</div></div>
                    </router-link>
                    <router-link to="/factures-fournisseur" :class="$route.name === 'supplier-invoices' ? 'selected' : ''">
                        Factures fournisseurs
                        <div><div>Factures fournisseurs</div></div>
                    </router-link>
                    <router-link to="/articles" :class="$route.name === 'articles' ? 'selected' : ''">
                        Stocks/Articles
                        <div><div>Stocks/Articles</div></div>
                    </router-link>
                </div>
            </div>
            <div class="submenu-container" v-if="$store.getters.hasRoleInList([accountRoleEnum.ADMIN, accountRoleEnum.WORKSHOP_MANAGER])">
                VENTES
                <font-awesome-icon class="fa-icon" :icon="['fas', 'caret-down']" />
                <div class="submenu">
                    <router-link to="/clients" :class="$route.name === 'customers' ? 'selected' : ''">
                        Clients
                        <div><div>Clients</div></div>
                    </router-link>
                    <router-link to="/devis-client" :class="$route.name === 'client-quotations' ? 'selected' : ''">
                        Devis clients
                        <div><div>Devis clients</div></div>
                    </router-link>
                    <router-link to="/commandes-client" :class="$route.name === 'client-orders' ? 'selected' : ''">
                        Commandes clients
                        <div><div>Commandes clients</div></div>
                    </router-link>
                    <router-link to="/ar-commandes" :class="$route.name === 'ar-orders' ? 'selected' : ''">
                        AR de commandes
                        <div><div>AR de commandes</div></div>
                    </router-link>
                    <router-link to="/pro-formas" :class="$route.name === 'pro-formas' ? 'selected' : ''">
                        Pro forma
                        <div><div>Pro forma</div></div>
                    </router-link>
                    <router-link to="/bons-livraison" :class="$route.name === 'delivery-form' ? 'selected' : ''">
                        Bon de livraison clients
                        <div><div>Bon de livraison clients</div></div>
                    </router-link>
                    <router-link to="/factures-client" :class="$route.name === 'customer-invoice' ? 'selected' : ''">
                        Factures clients
                        <div><div>Factures clients</div></div>
                    </router-link>
                </div>
            </div>
            <div v-if="$store.getters.hasRoleInList([accountRoleEnum.ADMIN, accountRoleEnum.WORKSHOP_MANAGER, accountRoleEnum.TECHNICIAN_MANAGER])" class="submenu-container">
                Paramétrage
                <font-awesome-icon class="fa-icon" :icon="['fas', 'caret-down']" />
                <div class="submenu">
                    <router-link to="/utilisateurs" :class="$route.name === 'utilisateurs' ? 'selected' : ''" v-if="!$store.getters.hasRoleInList([accountRoleEnum.WORKSHOP_MANAGER, accountRoleEnum.TECHNICIAN_MANAGER])">
                        Utilisateurs
                        <div><div>Utilisateurs</div></div>
                    </router-link>
                    <router-link to="/broches" :class="$route.name === 'broches' ? 'selected' : ''" v-if="!$store.getters.hasRoleInList([accountRoleEnum.TECHNICIAN_MANAGER])">
                        Broches
                        <div><div>Broches</div></div>
                    </router-link>
                    <router-link to="/disciplines" :class="$route.name === 'disciplines' ? 'selected' : ''" v-if="!$store.getters.hasRoleInList([accountRoleEnum.TECHNICIAN_MANAGER])">
                        Disciplines
                        <div><div>Disciplines</div></div>
                    </router-link>
                    <router-link to="/types-nomenclatures" :class="$route.name === 'nomenclatureTypes' ? 'selected' : ''">
                        Types de nomenclature
                        <div><div>Types de nomenclature</div></div>
                    </router-link>
                    <router-link to="/natures-article" :class="$route.name === 'article-kinds' ? 'selected' : ''" v-if="!$store.getters.hasRoleInList([accountRoleEnum.TECHNICIAN_MANAGER])">
                        Natures d'article
                        <div><div>Natures d'article</div></div>
                    </router-link>
                    <router-link to="/familles-article" :class="$route.name === 'article-family' ? 'selected' : ''" v-if="!$store.getters.hasRoleInList([accountRoleEnum.TECHNICIAN_MANAGER])">
                        Familles d'article
                        <div><div>Familles d'article</div></div>
                    </router-link>
                    <router-link to="/documents" :class="$route.name === 'documents' ? 'selected' : ''" v-if="!$store.getters.hasRoleInList([accountRoleEnum.TECHNICIAN_MANAGER])">
                        Documents
                        <div><div>Documents</div></div>
                    </router-link>
                    <router-link to="/lignes-preformatees" :class="$route.name === 'manual-invoice-lines' ? 'selected' : ''" v-if="!$store.getters.hasRoleInList([accountRoleEnum.TECHNICIAN_MANAGER])">
                        Lignes préformatées
                        <div><div>Lignes préformatées</div></div>
                    </router-link>
                    <router-link to="/ribs" :class="$route.name === 'ribs' ? 'selected' : ''" v-if="!$store.getters.hasRoleInList([accountRoleEnum.TECHNICIAN_MANAGER])">
                        RIBs
                        <div><div>RIBs</div></div>
                    </router-link>
                    <router-link to="/parametres" :class="$route.name === 'parameters' ? 'selected' : ''" v-if="!$store.getters.hasRoleInList([accountRoleEnum.TECHNICIAN_MANAGER])">
                        Paramètres
                        <div><div>Paramètres</div></div>
                    </router-link>
                    <router-link to="/generateur-etat" :class="$route.name === 'state-generator' ? 'selected' : ''" v-if="!$store.getters.hasRoleInList([accountRoleEnum.TECHNICIAN_MANAGER])">
                        Générateur d'état
                        <div><div>Générateur d'état</div></div>
                    </router-link>
                </div>
            </div>
            <router-link to="/pointage" :class="$route.name === 'pointage' ? 'selected' : ''" v-if="!$store.getters.isCustomer">Pointage</router-link>
            <router-link to="/planning-taches" :class="$route.name === 'planning-taches' ? 'selected' : ''" v-if="!$store.getters.isCustomer && !$store.getters.isTechnician && !$store.getters.isWorkshopManager">Planning</router-link>
            <router-link to="/planning-taches-tab" :class="$route.name === 'planning-taches-tab' ? 'selected' : ''" v-if="$store.getters.isTechnician">Planning</router-link>
        </div>
    </div>
    <Dialog ref="userDialog" />
</template>

<script>
    import Dialog from '../../components/App/Dialog'
    import $ from 'jquery';
    import anime from 'animejs'
    import { AUTHENTICATION_LOGOUT } from '../../store/actions/authentication';
    import accountRoleEnum from '../../enums/accountRoleEnum';
    import {DROPBOX_REFRESH_ACCESS_TOKEN} from "@/store/actions/dropboxToken";
    import config from "@/config";

    export default {
        name: 'Topbar',
        components: {
            Dialog
        },
        mounted() {
            let submenuContainers = $('.submenu-container');

            submenuContainers.on('mouseenter', function () {
                let submenu = $(this).find('.submenu');
                submenu.show();
                anime({
                    targets: submenu.get(0),
                    duration: 150,
                    easing: 'easeOutSine',
                    translateX: ['-50%', '-50%'],
                    translateY: ['40px', '62px'],
                    scale: [0.9, 1],
                    opacity: [0, 1]
                });
            });

            submenuContainers.on('mouseleave', function () {
                let submenu = $(this).find('.submenu');
                anime({
                    targets: submenu.get(0),
                    duration: 150,
                    easing: 'easeOutSine',
                    translateX: ['-50%', '-50%'],
                    translateY: ['62px', '40px'],
                    scale: [1, 0.9],
                    opacity: [1, 0],
                    complete: function () {
                        submenu.hide();
                    }
                });
            });

          this.$store.dispatch(DROPBOX_REFRESH_ACCESS_TOKEN, config.DROPBOX_REFRESH_TOKEN);
        },
        data() {
            return {
                accountRoleEnum
            }
        },
        methods: {
            logOut() {
                this.$store.dispatch(AUTHENTICATION_LOGOUT);
            },
            displayUserInfo() {
                let role = null;

                switch (this.$store.state.authentication.user.role) {
                    case 'admin':
                        role = 'Administrateur';
                        break;
                    case 'technician':
                        role = 'Agent';
                        break;
                    case 'technicianManager':
                        role = 'Responsable agent';
                        break;
                    case 'workshopManager':
                        role = 'ADV';
                        break;
                    case 'customer':
                        role = 'Client';
                        break;
                }

                this.$refs.userDialog.show({
                    type: 'alert',
                    title: 'Utilisateur',
                    message: `${this.$store.state.authentication.user.firstname} ${this.$store.state.authentication.user.lastname} (${role})`,
                    okButton: 'OK',
                });
            },
            refresh() {
                window.location.reload();
            },
            cast() {
                document.dispatchEvent(new CustomEvent('cast'));
            }
        }
    }
</script>
