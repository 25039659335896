<template>
    <div :class="{ 'tablet': $store.getters.isFromAppTablet, 'mobile': !$store.getters.isFromAppTablet && isMobile }">
        <AppLayout>
        <router-view />
        </AppLayout>
    </div>
</template>

<script>
    import axios from 'axios';
    import config from "@/config";

    export default {
        name: 'App',
        data: () => ({
            loader: null,
            isMobile: screen.width <= 768
        }),
        mounted() {
            let that = this;

            // Événement envoyé par l'application tablette (permet d'afficher le spinner)
            document.addEventListener('spinnerShow', function() {
                that.loader = that.$loading.show({
                    'background-color': '#000000',
                    'color': '#FFFFFF'
                });
            });

            // Événement envoyé par l'application tablette (permet de cacher le spinner)
            document.addEventListener('spinnerHide', function() {
                if (that.loader !== null) {
                    that.loader.hide();
                    that.loader = null;
                }
            });

            // Événement envoyé par l'application tablette (permet de savoir qu'on est sur tablette)
            document.addEventListener('appTabletPing', function() {
                localStorage['isFromAppTablet'] = true;
            });

            // Événement envoyé par l'application tablette (permet de récupérer le token de push de la tablette)
            document.addEventListener('pushToken', function(data) {
                axios
                .put("account/updatePushToken", { pushToken: data.detail.pushToken })
                .then(() => {})
                .catch(() => {});
            });

            // Ajout de la librairie Dropbox
            let dropboxScript = document.createElement('script')
            dropboxScript.setAttribute('src', 'https://www.dropbox.com/static/api/2/dropins.js')
            dropboxScript.setAttribute('id', 'dropboxjs')
            dropboxScript.setAttribute('data-app-key', config.DROPBOX_APP_KEY)
            document.head.appendChild(dropboxScript)
        }
    }
</script>
