import { createRouter, createWebHistory } from 'vue-router'
import { AFFAIR_STATUS_LIST } from './store/actions/affairStatus';
import store from '@/store'
import { AUTHENTICATION_LOGOUT } from './store/actions/authentication';

const ifNotAuthenticated = (to, from, next) => {
    if (!store.getters.isAuthenticated) {
        next();
        return;
    }
    next('/');
}

const ifAuthenticated = async (to, from, next, condition = true) => {
    if (store.getters.isAuthenticated && condition) {
        // Initialisation de la liste des statuts
        if (store.getters.getAffairStatusList === null) {
            await store.dispatch(AFFAIR_STATUS_LIST);
        }

        next();
        return;
    }

    next('/connexion');
}

const routes = [
    {
        path: '/',
        name: '/',
        component: () => import('@/views/App/Home.vue'),
        beforeEnter: (to, from, next) => {
            if (store.getters.isAuthenticated) {
                if ((store.getters.isTechnician || store.getters.isTechnicianManager) && store.getters.isFromAppTablet) {
                    next('/affaires-tab');
                } else {
                    next('/affaires');
                }
            } else {
                next('/connexion');
            }
        },
    }, {
        path: '/connexion',
        name: 'connexion',
        component: () => import('@/views/App/Login.vue'),
        beforeEnter: ifNotAuthenticated,
        meta: {
            layout: 'AppNoLayout'
        }
    }, {
        path: '/utilisateurs',
        name: 'utilisateurs',
        component: () => import('@/views/Account/Accounts.vue'),
        beforeEnter: ifAuthenticated,
    }, {
        path: '/reinitialisationMotDePasse',
        name: 'reinitialisationMotDePasse',
        component: () => import('@/views/Account/ResetPassword.vue'),
        beforeEnter: ifNotAuthenticated,
        meta: {
            layout: 'AppNoLayout'
        }
    }, {
        path: '/disciplines',
        name: 'disciplines',
        component: () => import('@/views/Discipline/Disciplines.vue'),
        beforeEnter: ifAuthenticated,
    }, {
        path: '/broches',
        name: 'broches',
        component: () => import('@/views/Spindle/Spindles.vue'),
        beforeEnter: ifAuthenticated,
    }, {
        path: '/articles',
        name: 'articles',
        component: () => import('@/views/Article/Articles.vue'),
        beforeEnter: ifAuthenticated,
    }, {
        path: '/types-nomenclatures',
        name: 'nomenclatureTypes',
        component: () => import('@/views/NomenclatureType/NomenclatureTypes.vue'),
        beforeEnter: ifAuthenticated,
    }, {
        path: '/documents',
        name: 'documents',
        component: () => import('@/views/Document/Documents.vue'),
        beforeEnter: ifAuthenticated,
    }, {
        path: '/clients',
        name: 'customers',
        component: () => import('@/views/Customer/Customers.vue'),
        beforeEnter: ifAuthenticated,
    }, {
        path: '/fournisseurs',
        name: 'suppliers',
        component: () => import('@/views/Supplier/Suppliers.vue'),
        beforeEnter: ifAuthenticated,
    }, {
        path: '/affaires-tab',
        name: 'affaires-tab',
        component: () => import('@/views/Affair/TabletAffairs.vue'),
        beforeEnter: (to, from, next) => {
            ifAuthenticated(to, from, next, store.getters.isTechnicianManager || store.getters.isTechnician);
        },
        meta: {
            layout: 'AppLayoutNoPadding'
        }
    }, {
        path: '/affaires',
        name: 'affaires',
        component: () => import('@/views/Affair/Affairs.vue'),
        beforeEnter: ifAuthenticated
    },
    {
        path: '/pointage',
        name: 'pointage',
        component: () => import('@/views/ClockingTasks/ClockingTasks.vue'),
        beforeEnter: ifAuthenticated
    }, {
        path: '/planning-taches',
        name: 'planning-taches',
        component: () => import('@/views/Planning/PlanningTasks.vue'),
        beforeEnter: ifAuthenticated,
        meta: {
            layout: 'AppLayoutNoPaddingNoBackground'
        }
    }, {
        path: '/planning-taches-tab',
        name: 'planning-taches-tab',
        component: () => import('@/views/Planning/TabletPlanningTasks.vue'),
        beforeEnter: (to, from, next) => {
            ifAuthenticated(to, from, next, store.getters.isTechnician);
        },
        meta: {
            layout: 'AppLayoutNoPaddingNoBackground'
        }
    }, {
        path: '/natures-article',
        name: 'article-kinds',
        component: () => import('@/views/ArticleKind/ArticleKinds.vue'),
        beforeEnter: ifAuthenticated,
    }, {
        path: '/mouvements-stock',
        name: 'stock-movements',
        component: () => import('@/views/StockMovement/StockMovements.vue'),
        beforeEnter: ifAuthenticated,
    }, {
        path: '/demandes-prix',
        name: 'price-requests',
        component: () => import('@/views/PriceRequest/PriceRequests.vue'),
        beforeEnter: ifAuthenticated,
    }, {
        path: '/commandes',
        name: 'orders',
        component: () => import('@/views/Order/Orders.vue'),
        beforeEnter: ifAuthenticated,
    }, {
        path: '/receptions',
        name: 'receptions',
        component: () => import('@/views/Reception/Receptions.vue'),
        beforeEnter: ifAuthenticated,
    }, {
        path: '/factures-fournisseur',
        name: 'supplier-invoices',
        component: () => import('@/views/SupplierInvoice/SupplierInvoices.vue'),
        beforeEnter: ifAuthenticated,
    }, {
        path: '/parametres',
        name: 'parameters',
        component: () => import('@/views/Parameter/Parameters.vue'),
        beforeEnter: ifAuthenticated,
    }, {
        path: '/:pathMatch(.*)*',
        redirect: '/'
    }, {
        path: '/familles-article',
        name: 'article-family',
        component: () => import('@/views/ArticleFamily/ArticleFamilies.vue'),
        beforeEnter: ifAuthenticated,
    }, {
        path: '/devis-client',
        name: 'client-quotations',
        component: () => import('@/views/Quotation/Quotations.vue'),
        beforeEnter: ifAuthenticated,
    }, {
        path: '/commandes-client',
        name: 'client-orders',
        component: () => import('@/views/Quotation/ClientOrders.vue'),
        beforeEnter: ifAuthenticated,
    }, {
        path: '/bons-livraison',
        name: 'delivery-form',
        component: () => import('@/views/DeliveryForm/DeliveryForms.vue'),
        beforeEnter: ifAuthenticated,
    }, {
        path: '/ar-commandes',
        name: 'ar-orders',
        component: () => import('@/views/AROrder/AROrders.vue'),
        beforeEnter: ifAuthenticated,
    }, {
        path: '/factures-client',
        name: 'customer-invoice',
        component: () => import('@/views/CustomerInvoice/CustomerInvoices.vue'),
        beforeEnter: ifAuthenticated,
    }, {
        path: '/emails',
        name: 'email',
        component: () => import('@/views/Email/Emails.vue'),
        beforeEnter: ifAuthenticated,
    }, {
        path: '/lignes-preformatees',
        name: 'manual-invoice-lines',
        component: () => import('@/views/ManualInvoiceLine/ManualInvoiceLines.vue'),
        beforeEnter: ifAuthenticated,
    }, {
        path: '/pro-formas',
        name: 'pro-formas',
        component: () => import('@/views/ProForma/ProFormas.vue'),
        beforeEnter: ifAuthenticated,
    }, {
        path: '/ribs',
        name: 'ribs',
        component: () => import('@/views/Rib/Ribs.vue'),
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/generateur-etat',
        name: 'state-generator',
        component: () => import('@/views/StateGenerator/StateGenerator.vue'),
        beforeEnter: ifAuthenticated
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
