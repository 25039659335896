<style src="../assets/css/fonts.css"></style>
<style src="../assets/css/helper.css"></style>
<style src="../assets/css/layout.css"></style>

<style scoped>
    #main-container {
        background-color: #E1E6EB;
        min-height: 100vh;
    }

    #content {
        display: flex;
        flex: 1;
        position: relative;
        box-sizing: border-box;
        padding-top: 65px;
    }
</style>

<template>
    <div id="main-container" v-if="$store.getters.isAuthenticated">
        <Topbar />
        <div id="content">
            <slot />
        </div>
    </div>
</template>

<script>
    import Topbar from '../components/Topbar/Topbar'

    export default {
        name: 'AppLayoutNoPaddingNoBackground',
        components: {
            Topbar
        }
    }
</script>