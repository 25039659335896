<style src="../assets/css/fonts.css"></style>
<style src="../assets/css/helper.css"></style>
<style src="../assets/css/layout.css"></style>

<style scoped>
    #spindle-background {
        width: 100vw;
        height: 100vh;
        position: fixed;
        object-fit: cover;
    }

    #content {
        display: flex;
        flex: 1;
        position: relative;
        box-sizing: border-box;
        padding: 95px 30px 30px 30px;
    }
</style>

<template>
    <div v-if="$store.getters.isAuthenticated">
        <picture>
            <source srcset="../assets/images/spindle-background.webp" type="image/webp">
            <source srcset="../assets/images/spindle-background.png" type="image/png">
            <img id="spindle-background" src="../assets/images/spindle-background.webp" alt="Image d'une broche">
        </picture>
        <Topbar />
        <div id="content">
            <slot />
        </div>
    </div>
</template>

<script>
    import Topbar from '../components/Topbar/Topbar'

    export default {
        name: 'AppLayoutDefault',
        components: {
            Topbar
        }
    }
</script>