export default {
    BACK_URL: String(process.env.VUE_APP_BACK_URL),
    API: String(process.env.VUE_APP_API),
    UPLOAD_DIRECTORY: String(process.env.VUE_APP_UPLOAD_DIRECTORY),
    GENERATED_DIRECTORY: String(process.env.VUE_APP_GENERATED_DIRECTORY),
    DROPBOX_URL: String(process.env.VUE_APP_DROPBOX_URL),
    // DROPBOX_ACCESS_TOKEN
    DROPBOX_APP_KEY: String(process.env.VUE_APP_DROPBOX_APP_KEY),
    DROPBOX_APP_SECRET: String(process.env.VUE_APP_DROPBOX_APP_SECRET),
    DROPBOX_REFRESH_TOKEN: String(process.env.VUE_APP_DROPBOX_REFRESH_TOKEN),
    DROPBOX_TEAM_MEMBER_ID: String(process.env.VUE_APP_DROPBOX_TEAM_MEMBER_ID),
    DROPBOX_CODE: String(process.env.VUE_APP_DROPBOX_CODE),
};