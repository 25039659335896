import { AUTHENTICATION_LOGIN, AUTHENTICATION_TAG, AUTHENTICATION_LOGOUT, AUTHENTICATION_TOKEN_CHANGE } from '../actions/authentication';
import axios from 'axios';
import jwtDecode from "jwt-decode";
import accountRoleEnum from '../../enums/accountRoleEnum';
import { createToast } from 'mosha-vue-toastify';

const token = localStorage.getItem('token') || null;

function getUser(token) {
    var tokenDecoded = token ? jwtDecode(token) : null;
    return tokenDecoded ? { 
        id: tokenDecoded.userId, 
        firstname: tokenDecoded.firstname, 
        lastname: tokenDecoded.lastname, 
        role: tokenDecoded.role 
    } : null;
}

const state = {
    token: token,
    user: getUser(token)
};

const getters = {
    isMobile: () => {
        return localStorage['isFromAppTablet'] !== 'true' && screen.width <= 768;
    },
    isFromAppTablet: (state) => {
        return localStorage['isFromAppTablet'] === 'true';
    },
    isAuthenticated: (state) => {
        return state.token !== null;
    },
    hasRole: (state) => (role) => {
        return state.user?.role === role;
    },
    hasRoleInList: (state, getters) => (roles) => {
        for (let role of roles) {
            if (getters.hasRole(role)) {
                return true;
            }
        }
        return false;
    },
    isAdmin: (state) => {
        return state.user?.role === accountRoleEnum.ADMIN;
    },
    isWorkshopManager: (state) => {
        return state.user?.role === accountRoleEnum.WORKSHOP_MANAGER;
    },
    isTechnicianManager: (state) => {
        return state.user?.role === accountRoleEnum.TECHNICIAN_MANAGER;
    },
    isTechnician: (state) => {
        return state.user?.role === accountRoleEnum.TECHNICIAN;
    },
    isCustomer: (state) => {
        return state.user?.role === accountRoleEnum.CUSTOMER;
    }
};

const actions = {
    [AUTHENTICATION_LOGIN]: (context, account) => {
        return new Promise((resolve, reject) => { 
            axios
            .post('account/authenticate', account, { 
                toastError: true, 
                showPreloader: true 
            })
            .then(response => {
                if (response.data) {
                    localStorage.setItem('token', response.data);
                    axios.defaults.headers.common['Authorization'] = `Bearer ${response.data}`;
                    context.commit(AUTHENTICATION_TOKEN_CHANGE, response.data);
                    // L'authentification s'est bien passée, on demande le token de push à la tablette
                    if (context.getters.isTechnician && context.getters.isFromAppTablet) document.dispatchEvent(new CustomEvent('getPushToken'));
                    resolve(true);
                } else {
                    reject();
                }
            })
            .catch(() => {
                context.dispatch(AUTHENTICATION_LOGOUT);
            });
        });
    },
    [AUTHENTICATION_TAG]: (context, tag) => {
        return new Promise((resolve, reject) => {
            axios
            .post('account/authenticateByTag', tag, { 
                toastError: true, 
                showPreloader: true 
            })
            .then(response => {
                if (response.data) {
                    localStorage.setItem('token', response.data);
                    axios.defaults.headers.common['Authorization'] = `Bearer ${response.data}`;
                    context.commit(AUTHENTICATION_TOKEN_CHANGE, response.data);
                    if (context.getters.isTechnician && context.getters.isFromAppTablet) document.dispatchEvent(new CustomEvent('getPushToken'));
                    resolve(true);
                } else {
                    reject();
                }
            })
            .catch(() => {
                context.dispatch(AUTHENTICATION_LOGOUT);
            });
        });
    },
    [AUTHENTICATION_LOGOUT]: (context, payload) => {
        localStorage.removeItem('token');
        delete axios.defaults.headers.common['Authorization'];
        context.commit(AUTHENTICATION_TOKEN_CHANGE, null);

        if (payload?.message) {
            createToast(payload.message, {
                position: 'bottom-right',
                type: 'danger',
                hideProgressBar: true,
                showIcon: true,
                transition: 'slide'
            });
        } else if (!payload?.cancelRedirect) {
            document.location = '/connexion';
        }
    }
};

const mutations = {
    [AUTHENTICATION_TOKEN_CHANGE]: (state, token) => {
        state.token = token;
        state.user = getUser(token);
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};