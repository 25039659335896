<style>
    button {
        font-family: montserrat, sans-serif;
        cursor: pointer;
        position: relative;
    }

    button:hover {
        opacity: 0.9;
    }

    button {
        -webkit-tap-highlight-color: transparent;
        outline: none;
        font-size: 12px;
    }

    .button-text {
        margin: 0px 10px;
    }

    .modal button {
        font-weight: 500;
        border: none;
        color: white;
        font-size: 13px;
        padding: 9px 15px;
        border-radius: 4px;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: 0.15s ease;
        margin: 10px;
        justify-content: center;
    }

    .ag-root-wrapper-body button, .small-button {
        box-sizing: border-box;
        font-size: 14px;
        font-weight: bold;
        color: white;
        border: none;
        border-radius: 50%;
        cursor: pointer;
        width: 38px;
        height: 38px;
    }

    .ag-root-wrapper-body button {
        margin-right: 8px;
    }

    .white-button {
        border: 1px solid rgb(100, 110, 120) !important;
        color: rgb(100, 110, 120) !important;
        background: #FFFFFF !important;
    }

    .blue-button {
        background: linear-gradient(90deg, #1e73be, #00a1cf) !important;
    }

    .orange-button {
        background: linear-gradient(90deg, #ff6b00, #ff9100) !important;
    }

    .green-button {
        background: linear-gradient(90deg, #4FA601 ,  #7ad12c) !important;
    }
    
    .grey-button {
        background: linear-gradient(90deg, #646e78 ,  #a0a5aa) !important;
    }

    .purple-button {
        background: linear-gradient(90deg, #8c4ebb ,  #c27cd9) !important;
    }

    .dark-blue-button {
        background: #0D3B70 !important;
    }

    .container-action-button .button {
        background: #0D3B70;
    }

    .container-action-button .button:nth-child(0n+1) {
        background: linear-gradient(90deg, #ff6b00, #ff9100);
    }

    .container-action-button .button:nth-child(0n+2) {
        background: linear-gradient(90deg, #1e73be, #00a1cf);
    }

    .topbar-right-container .button {
        font-weight: 500;
        border: none;
        background: linear-gradient(90deg, #1e73be, #00a1cf);
        color: white;
        padding: 9px 15px;
        border-radius: 4px;
        text-transform: uppercase;
        cursor: pointer;
        transition: 0.15s ease;
        display: flex;
    }

    .rectangle-button {
        display: flex !important;
        border-radius: 4px !important;
        align-items: center !important;
        width: fit-content !important;
        font-weight: 600 !important;
        font-size: 11px !important;
        padding: 8px 12px !important;
        margin: -8px 0 -8px auto !important;
        white-space: nowrap !important;
        min-width: 110px !important;
    }

    .rectangle-button.big-button{
        border-radius: 5px !important;
        width: fit-content !important;
        font-weight: 800 !important;
        font-size: 14px !important;
        padding: 12px 16px !important;
        margin: -12px 0 -12px auto !important;
        white-space: nowrap !important;
        min-width: 120px !important;
    }

    .rectangle-button .button-text {
        text-transform: uppercase !important;
        margin: 0px 0px 0px 10px !important;
    }
</style>

<template>
    <button v-if="!disabled || buttonText" :class="'button ' + buttonClass + (disabled ? ' disabled' : '')" :id="id" :type="type" :title="title">
        <slot name="iconBefore"></slot>
        <div class="button-text" v-if="buttonText">{{ buttonText }}</div>
        <slot name="iconAfter"></slot>
        <slot name="badge"></slot>
    </button>
</template>

<script>
    export default {
        name: 'BaseButton',
        props: { 
            buttonText: {
                type: String,
                default: ''
            },
            type: {
                type: String,
                default: 'button'
            },
            id: {
                type: String,
                default: 'text'
            },
            title: {
                type: String,
                default: ''
            },
            buttonClass: {
                type: String,
                default: ''
            },
            disabled: {
                type: Boolean,
                default: false
            }
        }
    }
</script>