import { AFFAIR_STATUS_LIST, AFFAIR_STATUS_SET_LIST } from '../actions/affairStatus';
import affairStatusEnum from '../../enums/affairStatusEnum';
import axios from 'axios';

const state = {
    affairStatusList: null
};

const getters = {
    getAffairStatusList: (state) => {
        return state.affairStatusList;
    }
};

const actions = {
    [AFFAIR_STATUS_LIST]: (context) => {
        return new Promise((resolve, reject) => {
            axios
            .get('affairStatus/list', { 
                toastError: true, 
                showPreloader: true 
            })
            .then(response => {
                if (response.data !== null) {
                    context.commit(AFFAIR_STATUS_SET_LIST, response.data);
                    resolve(true);
                } else {
                    context.commit(AFFAIR_STATUS_SET_LIST, null);
                    reject();
                }
            })
            .catch(() => {
                context.commit(AFFAIR_STATUS_SET_LIST, null);
                reject();
            });
        });
    }
};

const mutations = {
    [AFFAIR_STATUS_SET_LIST]: (state, affairStatusList) => {
        if(affairStatusList) {
            for(const id of Object.keys(affairStatusList)) {
                switch(id) {
                    case(affairStatusEnum.CANCELED):
                        affairStatusList[id].icon = ['fas', 'times'];
                        affairStatusList[id].customerIcon = ['fas', 'times'];
                    break;
                    case(affairStatusEnum.PENDING_ADMIN_VALIDATION):
                        affairStatusList[id].icon = ['fas', 'user-tie'];
                        affairStatusList[id].customerIcon = ['fas', 'clouser-hard-hatck'];
                        break;
                    case(affairStatusEnum.PENDING_EXPERT_ASSESSMENT_PLANNING):
                    case(affairStatusEnum.PENDING_INTERVENTION_REPORT_PLANNING):
                        affairStatusList[id].icon = ['fas', 'clock'];
                        affairStatusList[id].customerIcon = ['fas', 'user-hard-hat'];
                        break;
                    case(affairStatusEnum.PENDING_EXPERT_ASSESSMENT):
                        affairStatusList[id].icon = ['fas', 'clock'];
                        affairStatusList[id].customerIcon = ['fas', 'user-hard-hat'];
                        break;
                    case(affairStatusEnum.ONGOING_EXPERT_ASSESSMENT):
                        affairStatusList[id].icon = ['fas', 'user-hard-hat'];
                        affairStatusList[id].customerIcon = ['fas', 'user-hard-hat'];
                        break;
                    case(affairStatusEnum.PENDING_EXPERT_ASSESSMENT_VALIDATION):
                        affairStatusList[id].icon = ['fas', 'clock'];
                        affairStatusList[id].customerIcon = ['fas', 'user-hard-hat'];
                        break;
                    case(affairStatusEnum.EXPERT_ASSESSMENT_VALIDATED_PENDING_NOMENCLATURE):
                        affairStatusList[id].icon = ['fas', 'clock'];
                        affairStatusList[id].customerIcon = ['fas', 'user-hard-hat'];
                        break;
                    case(affairStatusEnum.NOMENCLATURE_VALIDATED_PENDING_EXPERT_ASSESSMENT):
                        affairStatusList[id].icon = ['fas', 'clock'];
                        affairStatusList[id].customerIcon = ['fas', 'user-hard-hat'];
                        break;
                    case(affairStatusEnum.PENDING_QUOTATION):
                        affairStatusList[id].icon = ['fas', 'user'];
                        affairStatusList[id].customerIcon = ['fas', 'clock'];
                        break;
                    case(affairStatusEnum.NOT_REPAIRABLE):
                        affairStatusList[id].icon = ['fas', 'times-circle'];
                        affairStatusList[id].customerIcon = ['fas', 'times-circle'];
                        break;
                    case(affairStatusEnum.TO_WRECK):
                        affairStatusList[id].icon = ['fas', 'dumpster'];
                        affairStatusList[id].customerIcon = ['fas', 'dumpster'];
                        break;
                    case(affairStatusEnum.PENDING_REPAIRS):
                        affairStatusList[id].icon = ['fas', 'clock'];
                        affairStatusList[id].customerIcon = ['fas', 'wrench'];
                        break;
                    case(affairStatusEnum.ONGOING_REPAIRS):
                        affairStatusList[id].icon = ['fas', 'wrench'];
                        affairStatusList[id].customerIcon = ['fas', 'wrench'];
                        break;
                    case(affairStatusEnum.PENDING_TESTS_PLANNING):
                        affairStatusList[id].icon = ['fas', 'clock'];
                        affairStatusList[id].customerIcon = ['fas', 'tachometer-alt-fastest'];
                        break;
                    case(affairStatusEnum.PENDING_TESTS):
                        affairStatusList[id].icon = ['fas', 'clock'];
                        affairStatusList[id].customerIcon = ['fas', 'tachometer-alt-fastest'];
                        break;
                    case(affairStatusEnum.ONGOING_TESTS):
                        affairStatusList[id].icon = ['fas', 'tachometer-alt-fastest'];
                        affairStatusList[id].customerIcon = ['fas', 'tachometer-alt-fastest'];
                        break;
                    case(affairStatusEnum.TO_BILL):
                        affairStatusList[id].icon = ['fas', 'money-check-edit-alt'];
                        affairStatusList[id].customerIcon = ['fas', 'money-check-edit-alt'];
                        break;
                    case(affairStatusEnum.TO_SHIP):
                        affairStatusList[id].icon = ['fas', 'truck-loading'];
                        affairStatusList[id].customerIcon = ['fas', 'truck-loading'];
                        break;
                    case(affairStatusEnum.BILLED):
                        affairStatusList[id].icon = ['fas', 'check'];
                        affairStatusList[id].customerIcon = ['fas', 'check'];
                        break;
                    case(affairStatusEnum.PENDING_CUSTOMER_DECISION):
                        affairStatusList[id].icon = ['fas', 'clock'];
                        affairStatusList[id].customerIcon = ['fas', 'clock'];
                        break;
                    case(affairStatusEnum.EXTERNAL_REPAIRS):
                        affairStatusList[id].icon = ['fas', 'tools'];
                        affairStatusList[id].customerIcon = ['fas', 'tools'];
                        break;
                    case(affairStatusEnum.RETURN_WITHOUT_REPAIR):
                        affairStatusList[id].icon = ['fas', 'ban'];
                        affairStatusList[id].customerIcon = ['fas', 'ban'];
                        break;
                }
            }
        }
        
        state.affairStatusList = affairStatusList;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};