export function formatPrice(value, event) {
    value = String(value).replace(/,/g, '.').replace(/€/g, '').trim();

    let part1 = '';
    let part2 = '';
    let decimalIndex = value.indexOf('.');
    
    if (decimalIndex !== -1) {
        part1 = value.substring(0, decimalIndex).replace(/\D/g,'');
        part2 = value.substring(decimalIndex + 1).replace(/\D/g,'');
    } else {
        part1 = value.replace(/\D/g,'');
        part2 = '';
    }
    
    if (part1.length === 0) {
        part2 = '';
    } else if (part2.length == 0) {
        part2 = '00';
    } else if (part2.length == 1) {
        part2 += '0';
    } else {
        part2 = part2.substring(0, 2);
    }

    value = part1 + (part2.length > 0 ? '.' + part2 : '');

    return value + ' €';
}

export function formatNumber(value) {
    return String(value).replace(/\D/g,'');
}

export function formatDecimal(value, decimalLength = 0, addSpace = false) {
    let originalValue = value;
    value = String(value).replace(/,/g, '.').trim();
    let part1 = '';
    let part2 = '';
    let decimalIndex = value.indexOf('.');

    if (decimalIndex !== -1) {
        part1 = value.substring(0, decimalIndex).replace(/\D/g,'');
        part2 = value.substring(decimalIndex + 1).replace(/\D/g,'');
    } else {
        part1 = value.replace(/\D/g,'');
        part2 = '';
    }
    
    if (part1.length === 0) {
        part2 = '';
    } else {
        if (part2.length < decimalLength) {
            while (part2.length < decimalLength) {
                part2 += '0';
            }
        } else {
            part2 = part2.substring(0, decimalLength);
        }
    }

    value = (originalValue < 0 ? '-' : '') + (addSpace ? part1.replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : part1) + (part2.length > 0 || decimalLength > 0 ? '.' : '') + part2;

    return value;
}